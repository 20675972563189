import styled from 'styled-components';
import { palette } from 'styled-tools';

import { maxWidth } from '@calm-web/design-system';
import { ChevronRightCenterd as _ChevronRightCenterd, TimeFilled as _Time } from '@calm-web/icons';

export const UpsellBannerWrapper = styled.a`
	background: rgba(255, 255, 255, 0.1);
	padding: 18px 20px;
	margin: 36px 0 48px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 16px;
	border: 2px solid rgba(255, 255, 255, 0.1);

	${maxWidth('tablet')} {
		margin: 24px 0;
	}
`;

export const IconAndCopyWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const Time = styled(_Time)`
	height: 24px;
	width: 36px;
	path {
		fill: ${palette('white')};
	}
`;

export const ChevronRightCenterd = styled(_ChevronRightCenterd)`
	height: 20px;
	width: 20px;
	transform: rotate(180deg);
	path {
		fill: ${palette('white')};
	}
`;

export const TimeIconWrapper = styled.div`
	background: ${palette('upsellIconBackgroundGradient')};
	border-radius: 8px;
	height: 36px;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
`;
